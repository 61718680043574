<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-prep">
    <lxp-main-header :show-title="true" :show-back="true" :title="eduMatTitle">
      <template v-slot:inner>
        <div class="header-column">
          <div class="kb-form-dropdown download-dropdown" :class="{'is-active': showAttachments}" data-offset="bottom-right" @click="showAttachments = !showAttachments">
            <button class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><i class="icon-download32"></i><span class="text">첨부파일</span></button>
            <div class="kb-form-dropdown-options">
              <div class="dropdown-option">
                <ul class="dropdown-option-list">
                  <li v-for="(item, idx) in fileItems" :key="idx" class="dropdown-option-item">
                    <a href="javascript:" class="dropdown-option-link" :title="item.fileName">
                      <span class="dropdown-option-text">{{item.fileName}}</span>
                      <span class="download-mb">{{item.fileSize}}MB</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="board-view-container">
        <div class="view-header">
          <h3 class="title">{{items.boardTitle}} </h3>
          <p class="subtitle"><span class="text">{{items.categoryName}}</span><span class="text">2021.6.10 14:40 등록</span><span class="text notbar">{{items.readCount}} 조회</span></p>
        </div>
        <div class="view-body min-component">
          <div class="view-content">
            <h4 class="title">Self & Social Learning (SSL)</h4>
            <p class="text">KB금융그룹은 ‘세상을 바꾸는 금융’이라는 그룹의 미션을 토대로 미래세대의 육성을 위한 자립 가능한 성장 기반조성을 지원하고, 지속 가능한 사회를 구현하기 위한 사회적 가치 창출을 기본 방향으로 하여 사회공헌 정책을 운영하고 있습니다. 특히 미래의 주인공인 ‘아동·청소년’들이 희망을 갖고 자신의 꿈을 펼칠 수 있도록 공평한 배움과 체험기회를 확대하며, ‘일자리’, ‘지역사회’ , ‘환경’ 부문에 대한 다양한 지원 사업을 수행합니다.</p>
            <figure class="image">
              <img src="../../../assets/lxp/images/@tmp/@tmp_jisike_01.jpg" alt="임시이미지" />
              <figcaption class="image-caption">2022년 지식e-러닝 1회차 학습자료 일부</figcaption>
            </figure>
            <p class="text">KB금융그룹은 ‘세상을 바꾸는 금융’이라는 그룹의 미션을 토대로 미래세대의 육성을 위한 자립 가능한 성장 기반조성을 지원하고, 지속 가능한 사회를 구현하기 위한 사회적 가치 창출을 기본 방향으로 하여 사회공헌 정책을 운영하고 있습니다. 특히 미래의 주인공인 ‘아동·청소년’들이 희망을 갖고 자신의 꿈을 펼칠 수 있도록 공평한 배움과 체험기회를 확대하며, ‘일자리’, ‘지역사회’ , ‘환경’ 부문에 대한 다양한 지원 사업을 수행합니다.</p>
            <p class="text">&nbsp;</p>
            <p class="text">정당의 설립은 자유이며, 복수정당제는 보장된다. 국회에 제출된 법률안 기타의 의안은 회기중에 의결되지 못한 이유로 폐기되지 아니한다. 다만, 국회의원의 임기가 만료된 때에는 그러하지 아니하다. 대통령은 국가의 원수이며, 외국에 대하여 국가를 대표한다. 이 헌법시행 당시의 대법원장과 대법원판사가 아닌 법관은 제1항 단서의 규정에 불구하고 이 헌법에 의하여 임명된 것으로 본다. 광물 기타 중요한 지하자원·수산자원·수력과 경제상 이용할 수 있는 자연력은 법률이 정하는 바에 의하여 일정한 기간 그 채취·개발 또는 이용을 특허할 수 있다.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->

</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {ref} from 'vue';
import {ACT_GET_KNOWLEDGE_VIEW, ACT_GET_KNOWLEDGE_VIEW_ATCH_FILE} from "@/store/modules/prep/prep";
import {getItem, getItems, lengthCheck} from "@/assets/js/util";
import {useStore} from "vuex";
export default {
  name: 'KnowledgeView',
  components: {LxpMainHeader},
  setup() {
    const eduMatTitle = ref('KB HRD 클라우드 신규 콘텐츠 등재 알림');
    const showAttachments = ref(false);

    const store = useStore();

    const items = ref([]);
    const fileItems = ref([]);

    const getKnowledgeView = () => {
      store.dispatch(`prep/${ACT_GET_KNOWLEDGE_VIEW}`, {
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItem(res);
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getKnowledgeView();

    const getKnowledgeViewFileList = () => {
      store.dispatch(`prep/${ACT_GET_KNOWLEDGE_VIEW_ATCH_FILE}`, {
      }).then(res => {
        if (lengthCheck(res)) {
          fileItems.value = getItems(res);
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getKnowledgeViewFileList();

    return {
      eduMatTitle,
      showAttachments,
      items,
      fileItems
    }
  }
};
</script>